<template>
<v-container>
    <v-row>
        <v-col cols="12">
            <dealof-day/>
        </v-col>
        <v-col cols="12">
            <order-table/>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import DealofDay from '../components/Sections/DealofDay.vue'
import OrderTable from '../components/ShopParts/OrderTable.vue'
  export default {
  components: { DealofDay, OrderTable },
    name: 'Order',
  }
</script>
