<template>
    <div>
        <v-btn small elevation="2" color="secondary" @click="showTracking = !showTracking"> 
            Track <v-icon>mdi-radar</v-icon>
        </v-btn> 
        <track-order v-if="showTracking" :order="order" @close="showTracking = false"/>       
    </div>
</template>

<script>
import TrackOrder from '../Sections/TrackOrder.vue'
export default {
  components: { TrackOrder },
    name: 'TrackBtn',
    props:[
        'order'
    ],
    data(){
        return{
            showTracking:false
        }
    },
    methods:{
        
    }
}
</script>

<style>

</style>