<template>
    <paystack
    :amount="amount * 100"
    :email="email"
    :paystackkey="publicKey"
    :reference="reference"
    :callback="processPayment"
    :close="close"
  >
    <v-btn small elevation="2" color="primary"> 
        Pay <v-icon>mdi-cash-check</v-icon>
    </v-btn>
 </paystack>
</template>

<script>
import axios from 'axios';
import {mapState} from "vuex";
import paystack from 'vue-paystack';
export default {
    name: 'Paystack',
    data(){
        return{
            publicKey:'pk_test_0b9f27b1b6d289afe16a3e1f8bc261f6873c89a4'
        }
    },
    
    components: {
        paystack
    },
    props:[
        'amount',
        'email',
        'username',
        'orderId'
    ],
    computed: {
        ...mapState({
            authToken : state => state.authToken
        }),
        reference() {
            let text = "";
            let possible =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for (let i = 0; i < 10; i++)
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            return text;
        }
    },
    methods: {
        processPayment(){
            let payment = {}
            payment.amount = this.amount
            payment.reference = this.reference
            let loader = this.$loading.show()
            axios.put(process.env.VUE_APP_BASEURL+'/orders/'+this.orderId, payment,
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken
                }
            })
            .then((response) => {
                console.log(response)
                this.$toast.success('Payment Success', {
                    timeout: 5000
                });
                this.$emit('paysuccess', true)           
            })
            .catch((error) => {
                this.$toast.error(error.response ? error.response.data.message : 'Network Error. Try after some time!', {
                    timeout: 5000
                });
            })
            .finally(() => {
                loader.hide()
            });
        },
        close: () => {
            console.log("You closed checkout page")
        }
    },
}
</script>

<style>

</style>