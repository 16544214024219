<template>
    <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="700"
    >

      <v-card>
        <v-toolbar elevation="0"> 
          <v-toolbar-title>Track Order</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider></v-divider>

        <v-card-text class="mt-2">
          <v-row>
              <v-col cols="12">
                  <v-alert outlined type="info">Track Code: <strong>{{order.delivery.tracking_code}}</strong></v-alert>
                  <v-simple-table>
                      <tbody>
                          <tr>
                              <td>Delivery Status</td>
                              <td><v-chip color="yellow">{{order.delivery.status}}</v-chip></td>
                          </tr>
                          <tr>
                              <td>Information</td>
                              <td>{{order.delivery.info}}</td>
                          </tr>
                          <tr>
                              <td>Order Details</td>
                              <td>
                                    {{order.currency}} {{order.amount_due}} <em>{{order.payment_status}}</em> <br>
                                    <span v-for="product in order.products" :key="product.id">
                                    <small>{{ product.name }}</small>, <br>
                                    </span>
                              </td>
                          </tr>
                      </tbody>
                  </v-simple-table>
              </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
    name: 'TrackOrder',
    data () {
      return {
        dialog: true,
      }
    },
    props:[
        'order'
    ],

    methods:{
        close (){
            this.$emit('close')
        },
    }
}
</script>

<style scoped>
td{
    white-space: pre !important;
}
</style>