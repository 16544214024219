<template>
<v-card>
    <v-card-text>
        <v-row>
            <v-col cols="12">
                <h3>My Orders: <span class="texr-right">{{orders.length}} orders</span></h3>
                <p v-if="orders.length == 0">
                    No Orders yet...
                </p>
            </v-col>
            <v-col cols="12">
                <v-simple-table class="table mb-4">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Amount</th>
                            <th>Action</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody v-for="order in orders" :key="order.order_id">
                        <tr>
                            <td>
                                <v-icon v-if="toggle == order.order_id" @click="hideProducts">mdi-chevron-up</v-icon>
                                <v-icon v-else @click="showProducts(order.order_id)">mdi-chevron-down</v-icon>
                            </td>
                            <td class="text-left font-weight-bold">
                                {{ order.currency }}{{ order.amount_due.toLocaleString() }}
                            </td>
                            <td v-if="order.payment_status == 'unpaid'" class="text-left">
                                <paystack :amount="order.amount_due" :email="order.customer.email" :username="order.customer.username" :orderId="order.order_id" @paysuccess="fetchOrdersApi"/>
                            </td>
                            <td v-else-if="order.delivery && order.delivery.status != 'delivered'" class="text-left">
                                <track-btn :order="order"/>                                
                            </td>
                            <td v-else class="text-left">
                                <v-chip color="yellow">Delivered</v-chip>
                            </td>                            
                            <td class="text-left"><small>{{order.updated_at}}</small></td>
                        </tr>
                        <tr v-if="toggle == order.order_id">
                            <td colspan="4">
                                <span v-for="product in order.products" :key="product.id">
                                    <small>{{ product.name }}</small>, <br>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>                
            </v-col>
        </v-row>
    </v-card-text>
</v-card>
</template>

<script>
import axios from 'axios';
import {mapState} from "vuex";
import TrackBtn from './TrackBtn.vue';
import Paystack from './Paystack.vue';
export default {
  components: { TrackBtn, Paystack },
    name: 'OrderTable',
    data: () => ({
        orders: [],
        toggle:false,
    }),
    computed : {
        ...mapState({
            authToken : state => state.authToken,
        }),
    },
    created() {
        this.fetchOrdersApi()
    },
    methods:{
        showProducts(i) {
            this.toggle = i
        },
        hideProducts() {
            this.toggle = false
        },
        fetchOrdersApi() {
            axios.get(process.env.VUE_APP_BASEURL+'/orders',
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken
                }
            })
            .then((response) => {
                this.orders = response.data.data           
            })
            .catch((error) => {
                this.$toast.error(error.response ? error.response.data.message : 'Network Error. Try after some time!', {
                    timeout: 5000
                });
            })
            .finally(() => {
            });
        }
    }
}
</script>

<style scoped>
.table{
    border: 1px solid #000 !important
}
.qty{
    border: 2px solid #000;
    width: 60px !important;
}
</style>